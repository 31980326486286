import "./style.scss";
import { Col, Row, Container } from "reactstrap";
import logoTrain from "../../Images/train_logo.png";
import logoAppStore from "../../Images/app_store_logo.png";
import { Link } from "react-router-dom";
function Header() {
  return (
    <Container className="">
      <header className="site-header">
        <Row className="justify-content-center align-self-center align-items-center">
          <Col className={"site-header-left "}>
            <Link to="/" className="train-logo">
              <img src={logoTrain} alt="" />
            </Link>
          </Col>
          <Col className={"site-header-right"}>
            <a href="https://apps.apple.com/us/app/train-fitness/id1548577496" className="app-store-logo " target="_blank">
              <img src={logoAppStore} alt="" />
            </a>
          </Col>
        </Row>
      </header>
    </Container>
  );
}

export default Header;
