import React from "react";
import "./style.scss";
import { Container, Row, Col } from "reactstrap";
import trainLogoWhite from "../../Images/train_logo_white.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col xs={8} md={7}>
            <Link to="/" className="footer-logo">
              <img src={trainLogoWhite} alt="" />
            </Link>
            <p className="footer-tagline">
              Automated workout tracking using Artificial Intelligence
            </p>
          </Col>
          <Col xs={4} md={3}>
            <ul className="footer-menu">
              <li>
                <a href="https://angel.co/company/train-fitness">Careers</a>
              </li>
              <li>
                <a href="https://trainfitness.ai/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://trainfitness.ai/terms">Terms of Use</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={2}>
            <ul className="footer-menu">
              <li>
                <a href="https://www.instagram.com/trainfitness.ai/">
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/train-fitness-ai/">
                  Linkedin
                </a>
              </li>
              <li>
                <a href="mailto:hello@trainfitness.ai">Email</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="copyright-text">
        2021 Train Fitness Inc. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
