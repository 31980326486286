import { useEffect, useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import "./style.scss";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  Label,
  ModalBody,
  Container,
  Alert,
  Spinner
} from "reactstrap";
import ParticleImage_1 from "../../Images/dataPrivacyImg.png";
import ParticleImage_2 from "../../Images/barbellStepUp.png";
import ParticleImage_3 from "../../Images/cableStandingRussianTwistLeft.png";
import ParticleImage_4 from "../../Images/db_overheadpress.png";
import ParticleImage_5 from "../../Images/dumbbellConcentrationCurl.png";
import ParticleImage_6 from "../../Images/dumbellClean.png";
import ParticleImage_7 from "../../Images/gobletsquat.png";
import ParticleImage_8 from "../../Images/plate_frontraise.png";
import ParticleImage_9 from "../../Images/reverse_bbl_bentoverrow.png";
import ParticleImage_10 from "../../Images/reverse_bbl_bentoverrow-1.png";
import { TweenMax, Linear } from "gsap";



function Join() {
  const [value, setValue] = useState()
  const [success, setSuccess] = useState()
  const [errmess, setErrmess] = useState()
  const [loading, setLoading] = useState(false)

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    var MR = function(X) {
        return Math.random() * X;
      },
      TwL = TweenMax,
      G = document.querySelectorAll(".flier img");

    function BTweens() {
      var W = window.innerWidth,
        H = 260,
        C = 1000;
      TwL.killTweensOf(BTweens);
      TwL.delayedCall(C * 10, BTweens);
      for (var i = G.length; i--;) {
        var c = C,
          BA = [],
          GWidth = G[i].offsetWidth,
          GHeight = G[i].offsetHeight;
        while (c--) {
          BA.push({ x: MR(W - GWidth), y: MR(H - GHeight) });
        }
        if (G[i].T) {
          G[i].T.kill();
        }
        G[i].T = TweenMax.to(G[i], C * 10, {
          bezier: { timeResolution: 0, type: "soft", values: BA },
          delay: i * 0.35,
          ease: Linear.easeNone
        });
      }
    }

    BTweens();
    window.onresize = function() {
      TwL.killTweensOf(BTweens);
      TwL.delayedCall(0.4, BTweens);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrmess("")
    setSuccess("")
    setLoading(true);

    const url = "https://gettrainfitness.herokuapp.com/index";
    //const url = "http://localhost:3000/index";

    fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          From: "+12722272724",
          To: value,
          Body: "Click link to download your Train Fitness app now: https://apps.apple.com/us/app/train-fitness/id1548577496"
        }),

    }).then((res) => {
        setLoading(false);

        if (res.ok) {
          //console.log('res ',res)
            setSuccess("Congratulations, your download link has been delivered!");
            setTimeout(() => {
              setSuccess("");
              setValue(null)
            }, 5000)
            return;

        }
        else {
          setLoading(false);
          setErrmess("Oops! Permission to send an SMS has not been enabled for the region indicated by your number.");
          setTimeout(() => {
            setErrmess("")
          }, 7000)
          return
        }
    })
    .catch((error) => {
      //console.log('errmess ',error)
      setLoading(false);
      setErrmess("No network, please check your internet connection and try again!")
      setTimeout(() => {
        setErrmess("")
      }, 5000)
    });
  }

  return (
    
    <>
      <Container fluid className="mt-5">
        <h1 className="join-title">Join the Train Fitness Community 💪</h1>
        <div className="flier">
          <img src={ParticleImage_1} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px, "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_2} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_3} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_4} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_5} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_6} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_7} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_8} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_9} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
          <img src={ParticleImage_10} style={{ width: 150, transform: "translate3d("+ (Math.random()*window.innerWidth)+"px,  "+(Math.random()*230)+"px, 0px)" }} />
        </div>
        <Form className="join-form mt-5">
          <FormGroup>
            {/* <Input
              type="tel"
              bsSize="lg"
              placeholder="Enter your phone number"
              className="join-input custom-input-b"
            /> */}
            <PhoneInput
                placeholder="Enter your phone number e.g. +12345678910"
                value={value}
                onChange={setValue}
            />
          </FormGroup>
          <FormGroup>
            <Button
              size="md"
              color="primary"
              className="w-100 btn-primary-custom"
              onClick={handleSubmit}
              disabled={!value}
            >
              <div className="spin_div">Get the App {" "} <div className="spin">{loading && ( <Spinner color="light"  type="grow" style={{ width: '1.5rem', height: '1.5rem' }} /> ) }</div></div>
            </Button>
            
              { success && 
                  (<Alert color="success" className="alert">
                  {success}
                  </Alert>)
              }
              { errmess && 
                (<Alert color="warning" className="alert">
                  {errmess}
                </Alert>)
              }
           
          </FormGroup>
          <FormGroup>
            <p className="join-note">
              *Apple Watch required. Available on iOS with Apple Watch only.
            </p>
           
          </FormGroup>
        </Form>

        
      </Container>
            
      {/* <div className="join-waitlist">
        <h2 className="join-waitlist-title">
          Don’t have an Apple Watch? <br />
          Join the waitlist!
        </h2>
        <Button type="button" className="join-waitlist-btn" onClick={toggle}>
          Join the Waitlist
        </Button>
        <p className="join-waitlist-subtitle">
          We’re expanding to other platforms soon!
        </p>
      </div> */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Form className="px-2">
            <h2 className="modal-title-c mt-3">What we'll need...</h2>
            <FormGroup className="my-4">
              <Input
                type="text"
                bsSize="lg"
                placeholder="Enter a name"
                className="custom-input-b "
              />
            </FormGroup>
            <FormGroup className="my-4">
              <Input
                type="email"
                bsSize="lg"
                placeholder="Enter your email address"
                className="custom-input-b "
              />
            </FormGroup>
            <FormGroup className="my-4">
              <Input
                type="tel"
                bsSize="lg"
                placeholder="Enter your phone number please"
                className="custom-input-b"
              />
            </FormGroup>

            <Label className="custom-label-b ">Which phone do you have?</Label>
            <div className="text-center">
              <div className="custom-checkbox-b">
                <Input type="checkbox" id="select-iphone" />
                <Label check for="select-iphone">
                  iPhone
                </Label>
              </div>
              <div className="custom-checkbox-b">
                <Input type="checkbox" id="select-android" />
                <Label for="select-android">Andriod</Label>
              </div>
            </div>

            <FormGroup className="mt-2 mb-4">
              <Label for="watchType" className="custom-label-b mb-3">
                Watch Type
              </Label>
              <Input
                type="select"
                name="watchType"
                id="watchType"
                className="custom-select-b"
              >
                <option>Apple Watch (Default)</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Button
                size="md"
                color="primary"
                className="w-100 btn-primary-custom btn-primary-custom-m mt-2 mb-4"
              >
                {" "}
                Submit
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Join;
