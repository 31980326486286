import React,{useEffect} from "react";
import "./styes.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import Router from "../Router/Router";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import {isIOS} from "react-device-detect";

function App() {
  useEffect(()=>{
    if(isIOS)
    {
      window.location.href = 'https://apps.apple.com/us/app/train-fitness/id1548577496';
    }
  })
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
