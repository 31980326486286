import { Route, Switch } from "react-router-dom";
import React from "react";
import Join from "../Join/Join";

function Router() {
  return (
    <Switch>
      <Route path="/">
        <Join />
      </Route>
    </Switch>
  );
}

export default Router;
